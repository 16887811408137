import * as React from "react";
import OtherServices from "@components/Services/OtherServices";
import ContactForm from "@components/ContactForm";
import * as styles from "./Apps.module.scss";

import reporting from "@images/apps/reporting_orange.svg";
import management from "@images/apps/management_orange.svg";
import monitoring from "@images/apps/monitoring_orange.svg";

const WebsitesComponent = ({metadata}) => {

  const features = [
    {
      title: "Reporting",
      content: "Making sense of your business data can be quick and easy with the help of one of our Business Intelligence dashboards and apps. Populate the visual indicators that best reflect real-time trends and patterns in your business' key performance indicators. Whether you’re trying to manage product inventories, customer support tickets, or campaigns, you can apply a dashboard to visualize what's working (and not working) in your business.",
      image: reporting
    },
    {
      title: "Management",
      content: "Enable your staff to solve problems for your customers in realtime – reducing response time, increasing accuracy, and ultimately providing a better experience.",
      image: management
    },
    {
      title: "Monitoring",
      content: "Get realtime updates on the factors that affect you and your business. From manufacturing and suppliers to stocks and crypto-currency, Magyk can build an app that turns external data into internal results.",
      image: monitoring
    },
  ]
console.log(metadata)
  return (
    <div className={`${styles.websites} container`}>
      <section className={styles.header}>
        <h1>Apps</h1>
      </section>
      {
        features.map(feature => <section key={feature.title} className={styles.websites_feature}>
          <div className={styles.feature_text}>
            <h2 className={styles.feature_title}>{feature.title}</h2>
            <p>{feature.content}</p>
          </div>
          <img
            src={feature.image}
            className={`${styles.websites_img} ${styles.img_one}`}
          />
        </section>)
      }
  
      <OtherServices metadata={metadata.other_services} />
  
      <section>
        <ContactForm />
      </section>
    </div>
  );
} 

export default WebsitesComponent;
