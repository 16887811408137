import * as React from "react";
import * as styles from "./OtherServices.module.scss";
import { Link } from "gatsby";

import iconEngagement from "@images/index_page/icon_engagement.svg";
import iconMarketing from "@images/index_page/icon_marketing.svg";
import iconApps from "@images/index_page/icon_apps.svg";
import iconWebsites from "@images/index_page/icon_websites.svg";


const ICONS = {
  "iconEngagement": iconEngagement,
  "iconMarketing": iconMarketing
}

const MarketingComponent = ({metadata}) => {
  console.log(metadata)
  return (
    <section className={styles.other_services}>
      <h2>{metadata.title}</h2>
      <div className={styles.buttons}>
        {
          metadata.services.map((element, key) => (
            <Link key={key} to={element.link} className={styles.services_button}>
              <span>
                {element.title} <img src={ICONS[element.icon]} />{" "}
              </span>
            </Link>
          ))
        }
      </div>
    </section>
  );
}

export default MarketingComponent;
