import * as React from "react";
import { graphql } from "gatsby"
import Layout from "@components/layout";
import SEO from "@components/seo";
import AppsComponent from "@components/Apps";
import { MetadataService } from "../../utils/MetadataService";

const WebsitesPage = ({ uri, data }) => {

  /* TODO fetch metadata when available */

  const { pageMetadata } = data;
  const metadata = MetadataService.parseMetadata(JSON.parse(JSON.stringify(pageMetadata || {}))).content;

  return (
    <Layout path={uri}>
      <SEO title={metadata.page_title} />
      <AppsComponent metadata={metadata} />
    </Layout>
  );
}


export const pageQuery = graphql`
  query AppsPageComponentQuery {
    pageMetadata(page_id: {eq: "services_websites"}) {
      content,
      page_id
    }
  }
`

export default WebsitesPage;
